<ng-container
  *ngIf="
    !(eventStateService.isPlantationBySiLoaded$ | async) ||
    !(eventStateService.isSourcingAreaLoaded$ | async)
  "
>
  <app-progress-bar-skeleton
    [width]="'100%'"
    [height]="'40rem'"
  ></app-progress-bar-skeleton>
</ng-container>

<p-tabView
  [ngClass]="
    !(eventStateService.isPlantationBySiLoaded$ | async) ||
    !(eventStateService.isSourcingAreaLoaded$ | async)
      ? 'hidden'
      : ''
  "
>
  <p-tabPanel header="{{ 'MAP_PAGE.SI_DETAILS' | transloco }}">
    <app-si-details-tab></app-si-details-tab>
  </p-tabPanel>
  <p-tabPanel header="{{ 'MAP_PAGE.SOURCING_AREA' | transloco }}">
    <app-sourcing-area-tab></app-sourcing-area-tab>
  </p-tabPanel>
</p-tabView>

<ng-template #sourcingArea> </ng-template>
