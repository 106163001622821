import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { PrimengExportsModule } from '../primeng-exports.module';
import { FormsModule } from '@angular/forms';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { TraceabilityTableComponent } from './traceability-table/traceability-table.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    PrimengExportsModule,
    FormsModule,
    RefreshButtonComponent,
    TraceabilityTableComponent,
    TranslocoPipe,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  header: string = 'DASHBOARD.MY_TRACEABILITY';
  periodOptions = [
    {
      label: this.translateLastNMonths(3),
      value: this.getPeriodValue(3),
    },
    {
      label: this.translateLastNMonths(6),
      value: this.getPeriodValue(6),
    },
    {
      label: this.translateLastNMonths(12),
      value: this.getPeriodValue(12),
    },
  ];
  selectedPeriod: string = this.getPeriodValue(12)!;
  lastUpdatedDate = '30/06/2023';

  constructor(
    private datePipe: DatePipe,
    private translocoService: TranslocoService
  ) {}

  getPeriodValue(lastNMonths: number) {
    const today = new Date();
    const month = new Date(today).setMonth(today.getMonth() - lastNMonths);

    return this.datePipe.transform(month, 'yyyy-MM-dd');
  }

  onSelectedPeriodChanged(period: string) {
    this.selectedPeriod = period;
  }

  translateLastNMonths(value: number) {
    return this.translocoService.translate('DASHBOARD.LAST_N_MONTHS', {
      value: value,
    });
  }
}
