import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RiskOverlapInfoComponent } from '../risk-overlap-info/risk-overlap-info.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-risk-overlap-details',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    TranslocoPipe,
  ],
  templateUrl: './risk-overlap-details.component.html',
  styleUrls: ['./risk-overlap-details.component.scss'],
})
export class RiskOverlapDetailsComponent implements OnInit {
  @Input() riskOverlapDetail: any;
  riskTypes = RiskTypesEnum;

  constructor(
    private dialogService: DialogService,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.riskOverlapDetail = this.dialogConfig.data;
    }
  }

  getMaxOverlap(overlapAreas: any) {
    const maxLengthPlantation = overlapAreas.reduce((max: any, current: any) =>
      current.overlappingPlantations.length > max.overlappingPlantations.length
        ? current
        : max
    );

    return maxLengthPlantation;
  }

  onViewMoreClicked() {
    this.dialogService.open(RiskOverlapDetailsComponent, {
      data: this.riskOverlapDetail,
      width: '50rem',
      height: '70rem',
    });
  }

  onRiskIconClicked() {
    if (this.riskOverlapDetail.riskType === this.riskTypes.WDPA) {
      return;
    }
    this.dialogService.open(RiskOverlapInfoComponent, {
      data: this.riskOverlapDetail,
      // header: this.riskOverlapDetail.label,
      width: '50rem',
    });
  }
}
