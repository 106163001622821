import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from '../../primeng-exports.module';

import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import {
  INITIAL_RISK_OVERLAP_DETAILS_STATE,
  RiskOverlapDetail,
  TraceabilityData,
} from 'src/app/models/traceability-state.model';
import { RiskOverlapDetailsComponent } from './risk-overlap-details/risk-overlap-details.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
@Component({
  selector: 'app-risk-overlap-panel',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskOverlapDetailsComponent,
    TranslocoPipe,
  ],
  templateUrl: './risk-overlap-panel.component.html',
  styleUrls: ['./risk-overlap-panel.component.scss'],
})
export class RiskOverlapPanelComponent implements OnInit, OnDestroy {
  selectedTraceabilityData: TraceabilityData[] = [];
  destroyed$ = new Subject<void>();
  riskOverlapDetails: RiskOverlapDetail[] = [
    ...INITIAL_RISK_OVERLAP_DETAILS_STATE,
  ];
  flattenedRisks: any[] = [];
  isRiskAssigned$ = new BehaviorSubject<boolean>(false);
  riskTypes = RiskTypesEnum;
  overlapAreaLength: number[] = [];

  get riskOverlapLabel() {
    const selectedPlantationsLength = this.selectedTraceabilityData.filter(
      (plantation) => plantation.checked === true
    ).length;
    return selectedPlantationsLength === 1
      ? this.translocoService.translate('MAP_PAGE.INDIVIDUAL_PLANTATION')
      : this.translocoService.translate('MAP_PAGE.AGGREGATED');
  }

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    public eventStateService: EventStateService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.listenOnCheckSelectedPlantations();
    // this.setSelfOverlapGeometry();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  listenOnCheckSelectedPlantations() {
    this.traceabilityStateService.checkedSelectedTraceabilityData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.length) {
          this.selectedTraceabilityData = [...data];
          this.assignRiskOverlapDetails();
        }
      });
  }

  assignRiskOverlapDetails() {
    const checkedTraceabilityData = [
      ...this.selectedTraceabilityData.filter((data) => data.checked),
    ];
    let risks = {
      plantationName: '',
      treeCoverLoss: [] as any,
      wdpa: [] as any,
    };

    checkedTraceabilityData.forEach((data) => {
      if (data.plantationData && data.plantationData?.length) {
        data.plantationData.forEach((plantation) => {
          if (plantation.risks) {
            if (plantation.risks?.tree_cover_loss_eudrv1?.riskValue) {
              risks.treeCoverLoss.push({
                plantationName: plantation.plantation_name,
                details: plantation.risks?.tree_cover_loss_eudrv1,
              });
            }
            if (plantation.risks?.wdpa_v1?.riskValue) {
              risks.wdpa.push({
                plantationName: plantation.plantation_name,
                details: plantation.risks?.wdpa_v1,
              });
            }
          }
        });
      }
    });

    const [treeCoverLossDetails, wdpaDetails, selfOverlapDetails] =
      this.riskOverlapDetails;

    treeCoverLossDetails.totalAreas = this.calculateTotalArea(
      risks.treeCoverLoss
    );
    treeCoverLossDetails.areas = this.calculateAreaPercentage(
      risks.treeCoverLoss
    );
    wdpaDetails.totalAreas = this.calculateTotalArea(risks.wdpa);
    wdpaDetails.areas = this.calculateAreaPercentage(risks.wdpa);

    this.isRiskAssigned$.next(true);
  }

  // setSelfOverlapGeometry() {
  //   this.isRiskAssigned$
  //     .pipe(
  //       take(2),
  //       switchMap((isRiskAssigned) =>
  //         this.crdStateService.riskOverlapDetails$.pipe(
  //           tap(([_, __, selfOverlap]) => {
  //             if (isRiskAssigned && selfOverlap.areas.length) {
  //               const plantationCodes =
  //                 this.utilityService.getPlantationCodesArray(
  //                   selfOverlap.areas,
  //                   'overlappingPlantationCode'
  //                 );

  //               this.crdStateService.getSelfOverlapGeometryData(
  //                 plantationCodes
  //               );
  //             }
  //           })
  //         )
  //       )
  //     )
  //     .subscribe(() => {});
  // }

  calculateTotalArea(risks: any[]) {
    const totalArea = Number(
      risks.reduce((sum, item) => sum + item.details.overlapArea, 0)
    ).toFixed(3);

    return `${totalArea} ha`;
  }

  calculateAreaPercentage(risks: any[], riskType?: RiskTypesEnum) {
    const areas: any[] = [];
    // if (riskType === RiskTypesEnum.SELF_OVERLAP) {
    //   risks.forEach((risk) => {
    //     const areaName = this.selectedTraceabilityData.find(
    //       (plantation) => plantation.plantation_code === risk.plantation_code
    //     )?.plantation_name;

    //     risk.risks.forEach((overlapRisk: any) => {
    //       areas.push({
    //         percentage: overlapRisk.overlapAreaPerc,
    //         value: Number(overlapRisk.overlapArea).toFixed(3),
    //         areaName: areaName,
    //         overlappingPlantation: overlapRisk.plantation_name,
    //         overlappingPlantationCode: overlapRisk.overlapping_plantation_code,
    //       });
    //     });
    //   });
    //   return areas;
    // }
    risks.forEach((risk) => {
      areas.push({
        percentage: risk.details.overlapAreaPerc,
        value: Number(risk.details.overlapArea).toFixed(3),
        areaName: risk.plantationName,
      });
    });

    return areas;
  }

  getMaxOverlap(overlapAreas: any) {
    const maxLengthPlantation = overlapAreas.reduce((max: any, current: any) =>
      current.overlappingPlantations.length > max.overlappingPlantations.length
        ? current
        : max
    );

    return maxLengthPlantation;
  }
}
