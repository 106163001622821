import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from '../primeng-exports.module';
import { TraceabilityStateService } from '../services/state-service/traceability-state.service';
import { ScreenEnum } from '../enums/screens.enum';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { MapComponent } from './map/map.component';
import { RiskOverlapPanelComponent } from './risk-overlap-panel/risk-overlap-panel.component';
import { SelectedSiDetailsSummaryComponent } from './selected-si-details-summary/selected-si-details-summary.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UtilityService } from '../services/utility.service';
import { EventStateService } from '../services/state-service/event-state.service';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-map-page',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RefreshButtonComponent,
    MapComponent,
    RiskOverlapPanelComponent,
    SelectedSiDetailsSummaryComponent,
    PageHeaderComponent,
    TranslocoPipe,
    PageHeaderComponent,
  ],
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
})
export class MapPageComponent implements OnInit, OnDestroy {
  header = 'Traceability Map';
  destroyed$ = new Subject<void>();
  subs$: Subscription[] = [];

  constructor(
    private traceabilityStateService: TraceabilityStateService,
    private utilityService: UtilityService,
    private eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.setSelectedSiGeometry();
  }

  ngOnDestroy(): void {
    this.traceabilityStateService.setCheckedSelectedTraceabilityData([]);
    this.traceabilityStateService.clearPlantationBySi();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.utilityService.unsubscribeSubs(this.subs$);
  }

  setSelectedSiGeometry() {
    this.traceabilityStateService.selectedTraceabilityData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res?.length) {
          const siListArray = this.utilityService.getSiListArray(res);
          this.eventStateService.isPlantationBySiLoaded = false;
          const getPlantationBySiSub$ =
            this.traceabilityStateService.getPlantationsBySi(siListArray);
          const getCompositionData$ =
            this.traceabilityStateService.getCompositionData(siListArray);

          this.subs$.push(getPlantationBySiSub$);
          this.subs$.push(getCompositionData$);
        }
      });
  }

  onBackClicked() {
    this.traceabilityStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
  }
}
