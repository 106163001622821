import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventStateService } from '../state-service/event-state.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  constructor(
    private http: HttpClient,
    private eventStateService: EventStateService
  ) { }

  getVerifySiNumbSecretKey(
    si_numb: string, secret_key: string
  ) {
    this.eventStateService.isVerifyingSecretKeyLoading = true;
    return this.http
      .get<any>(
        `${environment.TRACEABILITY_EXTERNAL}secret-key/verify/?si=${si_numb}&secret_key=${secret_key}`
      ).pipe(
        finalize(() => {
          this.eventStateService.isVerifyingSecretKeyLoading = false;
        })
      );
  }

  downloadGeoJSONFiles(
    si_numb: string) {
    this.eventStateService.isDownloadingExternalJsonFileLoading = true;
    return this.http
      .get<any>(
        `${environment.TRACEABILITY_EXTERNAL}plantations/download-euis/?si=${si_numb}`,
        { responseType: 'blob' as 'json' }
      ).pipe(
        finalize(() => {
          this.eventStateService.isDownloadingExternalJsonFileLoading = false;
        })
      );
  }

  downloadEudrPackage(si_numb: string) {
    this.eventStateService.isDownloadingExternalZipFileLoading = true;
    return this.http
      .get<any>(
        `${environment.TRACEABILITY_EXTERNAL}download-eudr-package/?si=${si_numb}`,
        { responseType: 'blob' as 'json'}
      ).pipe(
        finalize(() => {
          this.eventStateService.isDownloadingExternalZipFileLoading = false;
        })
      );
  }
}
