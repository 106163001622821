<div *ngIf="riskOverlapDetail" class="flex card-content flex-column">
  <div class="flex align-items-center mb-4 px-4">
    <app-risk-icon
      [marginClass]="'mr-2'"
      [hasTooltip]="false"
      [riskType]="riskOverlapDetail.riskType"
    ></app-risk-icon>
    <span class="header">{{ riskOverlapDetail.label | transloco }}</span>
  </div>

  <span class="risk-description-header px-4">{{
    "MAP_PAGE.RISK_DESCRIPTION" | transloco
  }}:</span>

  <p class="risk-description-content px-4">
    <!-- {{ riskOverlapDetail.description.info }} -->
    <!-- TODO: refactor, only quick and dirty solution -->
    <ng-container [ngSwitch]="riskOverlapDetail.riskType">
      <ng-container *ngSwitchCase="riskTypes.TREE_COVER_LOSS">
        The
        <a
          href="https://storage.googleapis.com/earthenginepartners-hansen/GFC-2022-v1.10/download.html"
          target="_blank"
          >Tree Cover Loss</a
        >
        dataset is a collaborative effort involving the GLAD (Global Land
        Analysis & Discovery) lab at the University of Maryland, Google, USGS,
        and NASA, which quantifies instances of tree cover loss across global
        land areas (excluding Antarctica and other Arctic islands) at an
        approximate resolution of 30 × 30 meters. The dataset employs
        time-series analysis of Landsat images to identify regions experiencing
        substantial tree cover loss from 2000 to 2022. Tree cover loss is
        specifically defined as "stand replacement disturbance," indicating the
        complete removal of tree cover canopy at the Landsat pixel scale. Such
        loss can stem from various causes, including human activities like
        forestry practices (e.g., timber harvesting or deforestation) and
        natural occurrences such as disease or storm damage.
      </ng-container>
      <ng-container *ngSwitchCase="riskTypes.SELF_OVERLAP">
        Plantations that intersect or overlap with other plantations within the
        same set of plotted polygons.
      </ng-container>
    </ng-container>
  </p>
</div>
