<!-- TODO: refactor - should be a new initialized component -->
<div class="flex card-content flex-column">
  <div
    *ngIf="!dialogConfig.data; else modalHeader"
    class="flex mb-4 align-items-center justify-content-between"
  >
    <span class="header">{{ riskOverlapDetail.label | transloco }}</span>
    <app-risk-icon
      [isClickable]="true"
      (click)="onRiskIconClicked()"
      [hasTooltip]="false"
      [riskType]="riskOverlapDetail.riskType"
    ></app-risk-icon>
  </div>
  <ng-template #modalHeader>
    <div class="flex px-4 align-items-center mb-4">
      <app-risk-icon
        [marginClass]="'mr-2'"
        [hasTooltip]="false"
        [riskType]="riskOverlapDetail.riskType"
      ></app-risk-icon>
      <span class="header">{{ riskOverlapDetail.label | transloco }}</span>
    </div>
  </ng-template>
  <div class="flex justify-content-between">
    <div class="flex flex-column">
      <span
        class="total-area-label mb-1"
        [ngClass]="{
          'px-4': dialogConfig.data
        }"
        >{{ "MAP_PAGE.TOTAL_AREAS" | transloco }}</span
      >
      <span
        class="total-area mb-2"
        [ngClass]="{
          'px-4': dialogConfig.data
        }"
        >{{ riskOverlapDetail.totalAreas }}</span
      >
    </div>
    <div class="flex flex-column">
      <span
        class="total-area-label mb-1 text-right"
        [ngClass]="{
          'px-4': dialogConfig.data
        }"
        >{{ "MAP_PAGE.TOTAL_COUNT" | transloco }}</span
      >
      <span
        class="total-area mb-2 text-right"
        [ngClass]="{
          'px-4': dialogConfig.data
        }"
        >{{ riskOverlapDetail?.areas?.length }}</span
      >
    </div>
  </div>
  <div
    class="breakdown flex flex-column"
    [ngClass]="{
      'breakdown-modal': dialogConfig.data
    }"
  >
    <span class="mb-1">{{ "MAP_PAGE.BREAKDOWN" | transloco }}</span>
    <p-table
      [value]="riskOverlapDetail.areas"
      [paginator]="!dialogConfig.data"
      [rows]="3"
    >
      <ng-template pTemplate="header" let-rowIndex="rowIndex">
        <tr>
          <th style="min-width: 10rem" pSortableColumn="percentage">
            {{ "MAP_PAGE.PERCENTAGE" | transloco }}
            <p-sortIcon field="percentage"></p-sortIcon>
          </th>
          <th style="min-width: 7rem" pSortableColumn="value">
            {{ "MAP_PAGE.HECTARES" | transloco }}
            <p-sortIcon field="value"></p-sortIcon>
          </th>
          <ng-container
            *ngIf="
              riskOverlapDetail.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTh
            "
          >
            <th class="plantation-column">
              {{ "MAP_PAGE.PLANTATION" | transloco }} 1
            </th>
            <th class="plantation-column">
              {{ "MAP_PAGE.PLANTATION" | transloco }} 2
            </th>
          </ng-container>
          <ng-template #notSelfOverlapTh>
            <th class="plantation-column">
              {{ "MAP_PAGE.PLANTATION" | transloco }}
            </th>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-area let-areaIndex="rowIndex">
        <tr>
          <td style="font-weight: 700">{{ area.percentage }}%</td>
          <td>{{ area.value }}</td>

          <ng-container
            *ngIf="
              riskOverlapDetail.riskType === riskTypes.SELF_OVERLAP;
              else notSelfOverlapTd
            "
          >
            <td>{{ area.areaName }}</td>

            <td class="plantation-column">
              {{ riskOverlapDetail.areas[areaIndex]?.overlappingPlantation }}
            </td>
          </ng-container>
          <ng-template #notSelfOverlapTd>
            <td>{{ area.areaName }}</td>
          </ng-template>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="3">
            <span class="no-risk-label">{{
              "MAP_PAGE.NO_RISK_FOUND" | transloco
            }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <span
    *ngIf="riskOverlapDetail.areas.length > 3 && !dialogConfig.data"
    class="expand-span view-more"
    (click)="onViewMoreClicked()"
    >{{ "MAP_PAGE.VIEW_MORE" | transloco }}</span
  >
</div>
