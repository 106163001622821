import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimengExportsModule } from './primeng-exports.module';
import { DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TraceabilityInterceptor } from './interceptors/traceability.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { provideTransloco } from '@jsverse/transloco';
import { APP_INITIALIZERS, TRANSLOCO_CONFIG } from './app.config';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';

const firebaseConfig = [
  provideFirebaseApp(() => initializeApp(environment.firebase)), // TODO: provide envrionment for firebase
  provideFirestore(() => getFirestore()),
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule.enablePersistence(),
  AngularFireStorageModule
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PrimengExportsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ...firebaseConfig,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceabilityInterceptor,
      multi: true,
    },
    MessageService,
    ConfirmationService,
    provideTransloco(TRANSLOCO_CONFIG),
    APP_INITIALIZERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
