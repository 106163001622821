import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get userInfoToken() {
    return sessionStorage.getItem('ARP_Token');
  }

  get localUserInfoToken() {
    return localStorage.getItem('ARP_Token');
  }

  set userInfoToken(token: string | null) {
    sessionStorage.setItem('ARP_Token', token || '');
    localStorage.setItem('ARP_Token', token || '');
  }
  
  get firebaseToken() {
    return sessionStorage.getItem('Firebase_Token');
  }

  set firebaseToken(token: string | null) {
    sessionStorage.setItem('Firebase_Token', token || '');
  }

  get offPlatformSecretKey() {
    return sessionStorage.getItem('OffPlatform_SecretKey');
  }

  set offPlatformSecretKey(token: string | null) {
    sessionStorage.setItem('OffPlatform_SecretKey', token || '');
  }

  constructor() {
    // TODO - add checking if embedded on standalone
    setInterval(() => {
      sessionStorage.removeItem('ARP_Token');
      sessionStorage.removeItem('Firebase_Token');
      localStorage.removeItem('ARP_Token')
    }, 1000 * 60 * 60 * 8);
  }
}
