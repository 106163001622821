import { RiskTypesEnum } from '../enums/risk-types.enum';

export const RISK_DESCRTIPTIONS = [
  {
    riskType: RiskTypesEnum.TREE_COVER_LOSS,
    info: `The Tree Cover Loss dataset is a collaborative effort involving the GLAD (Global Land Analysis & Discovery) lab at the University of Maryland, Google, USGS, and NASA, which quantifies instances of tree cover loss across global land areas (excluding Antarctica and other Arctic islands) at an approximate resolution of 30 × 30 meters. 
    The dataset employs time-series analysis of Landsat images to identify regions experiencing substantial tree cover loss from 2000 to 2022. 
    Tree cover loss is specifically defined as "stand replacement disturbance," indicating the complete removal of tree cover canopy at the Landsat pixel scale. Such loss can stem from various causes, including human activities like forestry practices (e.g., timber harvesting or deforestation) and natural occurrences such as disease or storm damage.`,

  },
  {
    riskType: RiskTypesEnum.WDPA,
    info: `The World Database on Protected Areas (WDPA) is a joint project between the UN Environment Programme and the International Union for Conservation of Nature (IUCN), overseen by the UN Environment Programme World Conservation Monitoring Centre (UNEP-WCMC). It is the most comprehensive global database of marine and terrestrial protected areas. The management involves active cooperation with governments, non-governmental organizations, academia, and industry stakeholders. As of August 2020, it encompasses over 260,000 protected areas, spanning 245 countries and territories globally.`
  },
  {
    riskType: RiskTypesEnum.SELF_OVERLAP,
    info: `Plantations that intersect or overlap with other plantations within the same set of plotted polygons.`
  }
];
