import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FormsModule } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { RiskLayer } from 'src/app/models/traceability-state.model';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-layers-map-control',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ProgressBarSkeletonComponent,
    TranslocoPipe,
  ],
  templateUrl: './layers-map-control.component.html',
  styleUrls: ['./layers-map-control.component.scss'],
})
export class LayersMapControlComponent implements OnInit, OnDestroy {
  @ViewChildren('checkboxes') checkboxes!: QueryList<ElementRef>;
  riskLayers: RiskLayer[] = [];

  checkedLayer!: string;
  destroyed$ = new Subject<void>();
  isRiskLayersColorsAssigned = false;
  getRiskLayersSub$!: Subscription;

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.initializeRiskLayers();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.traceabilityStateService.setRiskLayers([]);
    this.getRiskLayersSub$.unsubscribe();
  }

  initializeRiskLayers() {
    this.getRiskLayersSub$ = this.traceabilityStateService.getRiskLayers();
    this.traceabilityStateService.riskLayers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((riskLayers) => {
        this.riskLayers = riskLayers;
        if (!this.isRiskLayersColorsAssigned && this.riskLayers.length) {
          const colors = this.riskLayers.map((layer) => layer.color);
          this.assignLayerCheckboxColor(colors);
        }
      });
  }

  assignLayerCheckboxColor(colors: string[]) {
    this.isRiskLayersColorsAssigned = true;
    const checkboxes = document.getElementsByClassName(
      'p-checkbox-box p-highlight'
    );
    setTimeout(() => {
      for (let index = 0; index < checkboxes.length; index++) {
        const element = checkboxes[index];
        this.renderer.setStyle(element, 'background-color', colors[index]);
        this.renderer.setStyle(element, 'border-color', colors[index]);
      }
    }, 0);
  }

  onLayerSelect(checked: boolean, index: number) {
    this.riskLayers[index].checked = checked;
    this.traceabilityStateService.setRiskLayers([...this.riskLayers]);
  }
}
