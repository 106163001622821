<span
  [pTooltip]="label || getRiskIconTooltip(riskType)"
  tooltipPosition="bottom"
  [tooltipDisabled]="!hasTooltip"
  class="material-symbols-outlined action-icon warning-icon"
  [class]="marginClass"
  [ngClass]="{
    'tree-cover-loss-icon': riskType === riskTypes.TREE_COVER_LOSS,
    'wdpa-icon': riskType === riskTypes.WDPA,
    'self-overlap-icon': riskType === riskTypes.SELF_OVERLAP,
    'clickable': isClickable
  }"
  [ngStyle]="{ color: iconColor }"
>
  warning
</span>
