import { TranslocoService } from '@jsverse/transloco';
import { lastValueFrom, of } from 'rxjs';
import { UserConfigService } from './services/user-config.service';

/**
 * A factory function returning a promise to preload the languages assets
 * before starting the Angular app, used as an APP_INITIALIZER provider
 * @param translocoService used to set active language and load language asset
 * @returns a promise resolving the translation asset.
 */
export function preloadLanguage(translocoService: TranslocoService) {
  return () => {
    const regex = /language\/([^\\/?\s]+)/;
    const match = window.location.href.match(regex);
    let language = 'engb';
    if (match && match.length > 1) {
      language = match[1];
    }
    translocoService.setActiveLang(language);
    // TODO: remove when cn and in values integrated
    // console.log('language for Traceability', translocoService.getActiveLang());
    return lastValueFrom(translocoService.load(language));
  };
}

export function preloadTheme(userConfigService: UserConfigService) {
  return () => {
    const tenantId = new URL(window.location.href).searchParams.get('tenantId');
    if (tenantId) {
      userConfigService.setTheme(tenantId);
    }
    return of(true);
  };
}

export function initializeEnvironmentConfig(
  userConfigService: UserConfigService
) {
  return () => {
    const url = window.location.href.toLowerCase();
    const isCn = url.includes('agridencecn');
    console.log('initializeEnvironmentConfig', url, isCn);
    if (isCn) {
      userConfigService.setCnEnvironmentConfig();
    }
    return of(true);
  };
}
