import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { EmailToCounterpartyPayload } from 'src/app/models/email-to-counterparty.mode';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';

@Component({
  selector: 'app-email-to-counterparty',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './email-to-counterparty.component.html',
  styleUrls: ['./email-to-counterparty.component.scss']
})
export class EmailToCounterpartyComponent {
  counterparty: string = '';
  siNumber: string = '';
  emailCounterpartyFormGroup!: FormGroup;
  
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.siNumber = this.dialogConfig.data?.rowData?.si_number;
    this.counterparty = this.dialogConfig.data?.rowData?.counterparty;
    this.initializeEmailCounterpartyForm();
  }

  initializeEmailCounterpartyForm() {
    this.emailCounterpartyFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() {
    return this.emailCounterpartyFormGroup.get('email') as FormControl;
  }

  getEmailCounterpartyPayload(): EmailToCounterpartyPayload {
    return {
      si: this.siNumber,
      email: this.emailCounterpartyFormGroup.get('email')?.value
    }
  }

  sendEmailToCounterparty() {
    this.dashboardService.emailToCounterParty(this.getEmailCounterpartyPayload());
    this.dialogRef.close();
  }
}
