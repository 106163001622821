<div class="flex my-4 px-4">
    <span class="font-semibold text-lg">{{ 'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL_SUBHEADER' | transloco }}</span>
</div>
<div class="grid modal-table px-4 mb-5">
    <div class="col-12">
        <ng-container>
            <div class="flex flex-column" [formGroup]="emailCounterpartyFormGroup!">
                <div class="flex flex-column py-3">
                    <span>{{ 'DASHBOARD.ACTION_BUTTONS.COUNTERPARTY_NAME' | transloco }}</span>
                    <span class="font-bold">{{ counterparty }}</span>
                </div>
                    <span>{{ 'DASHBOARD.ACTION_BUTTONS.EMAIL_ADDRESS' | transloco }}</span>
                    <input class="my-1" type="text" pInputText  formControlName="email"/>
            </div>
        </ng-container>

  
    </div>
</div>
<div class="flex justify-content-end modal-action-section">
    <p-button
      class="mr-4 mt-4"
      [outlined]="true"
      >{{ 'DASHBOARD.ACTION_BUTTONS.CANCEL' | transloco }}</p-button
    >
    <p-button
      class="mr-4 mt-4"
      (onClick)="sendEmailToCounterparty()"
      [disabled]="!email.valid"
      >{{ 'DASHBOARD.ACTION_BUTTONS.CONFIRM' | transloco }}</p-button
    >
  </div>