import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PlantationsBySi,
  TraceabilityData,
} from 'src/app/models/traceability-state.model';
import { Subject, combineLatest, map, takeUntil } from 'rxjs';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { FormsModule } from '@angular/forms';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-si-details-tab',
  standalone: true,
  imports: [CommonModule, FormsModule, PrimengExportsModule, TranslocoPipe],
  templateUrl: './si-details-tab.component.html',
  styleUrls: ['./si-details-tab.component.scss'],
})
export class SiDetailsTabComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  selectedTraceabilityData: TraceabilityData[] = [];
  siDetailFields: { label: string; field: keyof TraceabilityData }[] = [
    {
      label: 'MAP_PAGE.DELIVERY_MONTH',
      field: 'delivery_datetime',
    },
    {
      label: 'MAP_PAGE.SYMBOL',
      field: 'factory_code',
    },
    {
      label: 'MAP_PAGE.GRADE',
      field: 'grade_name',
    },
    {
      label: 'MAP_PAGE.SI_WEIGHT',
      field: 'si_weight',
    },
    {
      label: 'MAP_PAGE.PLANTATIONS',
      field: 'plantationData',
    },
  ];

  constructor(
    private traceabilityStateService: TraceabilityStateService,
    private eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.initializeSelectedTraceabilityData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeSelectedTraceabilityData() {
    combineLatest([
      this.traceabilityStateService.selectedTraceabilityData$,
      this.traceabilityStateService.plantationsBySi$,
    ])
      .pipe(
        map(([traceabilityData, plantationsBySi]) => {
          if (plantationsBySi?.length && traceabilityData?.length) {
            const mappedTraceabilityData = [
              ...traceabilityData.map((data) => {
                const plantations = plantationsBySi.filter(
                  (plantation) => data.si_number === plantation.si
                );
                return { ...data, plantationData: plantations };
              }),
            ];

            return mappedTraceabilityData as TraceabilityData[];
          }
          if (plantationsBySi !== undefined) {
            return traceabilityData;
          }

          return [];
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        if (res?.length) {
          this.selectedTraceabilityData = res;
          this.traceabilityStateService.setCheckedSelectedTraceabilityData([
            ...this.selectedTraceabilityData,
          ]);
        }
      });
  }

  onSiChecked() {
    this.traceabilityStateService.setCheckedSelectedTraceabilityData([
      ...this.selectedTraceabilityData,
    ]);
  }

  onZoomPlantation(data?: PlantationsBySi[]) {
    if (data) {
      this.eventStateService.zoomPlantation = data;
    }
  }

  onHoverPlantation(data: PlantationsBySi) {
    this.eventStateService.hoveredPlantation = data;
  }

  onHoverPlantationLeave() {
    this.eventStateService.hoveredPlantation = null;
  }

  onToggleExpand(data: TraceabilityData) {
    data.isExpanded = !data.isExpanded;
  }
}
