import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { Chart, Plugin } from 'chart.js';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { CompositionData } from 'src/app/models/traceability-state.model';
import { Subject, takeUntil } from 'rxjs';
import { MappedCompositionData } from 'src/app/models/mapped-composition-data.model';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-sourcing-area-tab',
  standalone: true,
  imports: [CommonModule, PrimengExportsModule, TranslocoPipe],
  templateUrl: './sourcing-area-tab.component.html',
  styleUrls: ['./sourcing-area-tab.component.scss'],
})
export class SourcingAreaTabComponent implements OnInit, OnDestroy {
  data: any;
  options: any;
  donutColors = ['#1446ce', '#fbc02d', '#ff5449', '#2da63d'];

  isSourcingAreasExpanded = false;
  compositionData: MappedCompositionData[] = [];
  destroyed$ = new Subject<void>();

  siNumber!: string;

  constructor(private traceabilityStateService: TraceabilityStateService) {}

  ngOnInit() {
    // this.initializeDonutChart();
    this.traceabilityStateService.compositionData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          this.compositionData = this.buildHierarchy(res, null);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * TODO:
   * - check performance of recursion implementations
   * - move to data/state service
   * - update types
   *  */
  buildHierarchy(
    data: any[],
    parentId: number | null
  ): MappedCompositionData[] {
    const children = data.filter((item) => item.parent_id === parentId);

    if (children.length === 0) {
      return [];
    }

    return children.map((child) => ({
      ...child,
      children: this.buildHierarchy(data, child.id),
    }));
  }

  // TODO: check performance of recursion implementations
  toggleSourcingAreasExpand(data: MappedCompositionData) {
    data.isExpanded = !data.isExpanded;
    this.isSourcingAreasExpanded = this.checkIsSomeExpanded(
      this.compositionData
    );
  }

  toggleAllExpand() {
    this.isSourcingAreasExpanded = !this.isSourcingAreasExpanded;
    this.compositionData.forEach((parent) => {
      parent.isExpanded = this.isSourcingAreasExpanded;
      this.expandChildren(parent.children, this.isSourcingAreasExpanded);
    });
  }

  checkIsSomeExpanded(data: MappedCompositionData[]): boolean {
    return data.some((item) => item.isExpanded === true);
  }

  expandChildren(children: MappedCompositionData[], isExpanded: boolean) {
    if (children && children.length > 0) {
      children.forEach((child) => {
        child.isExpanded = isExpanded;
        this.expandChildren(child.children, isExpanded);
      });
    }
  }

  // TODO: Temporary comment out
  // initializeDonutChart() {
  //   this.data = {
  //     labels: ['District', 'Village', 'Collection Point', 'Plantations'],
  //     datasets: [
  //       {
  //         data: [300, 50, 100, 30],
  //         backgroundColor: this.donutColors,
  //         hoverBackgroundColor: this.donutColors,
  //       },
  //     ],
  //   };

  //   this.options = {
  //     cutout: '80%',
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //   };
  // }

  // totalValuePlugin(): Plugin[] {
  //   return [
  //     {
  //       id: 'custom_doughnut_chart_center_display',
  //       afterDraw: (chart: Chart) => {
  //         const ctx = chart.ctx;
  //         const value = '10,392.00 MT';
  //         const fontSize = Math.min(chart.width / 11, chart.height / 11);

  //         const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
  //         const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

  //         ctx.fillStyle = 'black';
  //         ctx.textAlign = 'center';

  //         const lineHeight = fontSize * 0.65;
  //         const totalHeight = lineHeight * 2.5;
  //         const valueX = centerX;
  //         const valueY = centerY - totalHeight / 2 + lineHeight;
  //         const chartLabelX = centerX;
  //         const chartLabelY = centerY + totalHeight / 2;
  //         const totalFontSize = fontSize * 0.6;

  //         ctx.font = `${fontSize}px Nunito Sans`;
  //         ctx.fillText(value, valueX, valueY);
  //         ctx.font = `${totalFontSize}px Nunito Sans`;
  //         ctx.fillText('Total Volume', chartLabelX, chartLabelY);
  //       },
  //     },
  //   ];
  // }
}
