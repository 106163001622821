<div *ngIf="isTradeConfirmation" class="flex mb-4">
  <span
    >{{ "DASHBOARD.CREATE_NEW_SI.SELECTED_TRADE_CONFIRMATION_NO" | transloco }}:
    {{ selectedItem.contract_number }}</span
  >
</div>
<div [formGroup]="createNewSiFormGroup!" class="create-new-si-form">
  <div class="grid mb-2 align-items-end">
    <div class="col-6">
      <div class="flex flex-column">
        <div class="flex align-items-end">
          <span class="mb-2">{{
            "DASHBOARD.CREATE_NEW_SI.SI_NUMBER" | transloco
          }}</span>
        </div>
        <input
          type="text"
          pInputText
          formControlName="siNumber"
          placeholder="Enter SI Number"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.COUNTERPARTY" | transloco
        }}</span>
        <input type="text" pInputText formControlName="counterParty" />
      </div>
    </div>
  </div>
  <div class="grid mb-2">
    <div class="col-6">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.SYMBOL" | transloco
        }}</span>
        <input
          *ngIf="isTradeConfirmation; else counterpartySymbol"
          type="text"
          pInputText
          formControlName="symbol"
        />

        <ng-template #counterpartySymbol>
          <p-dropdown
            optionLabel="factory_code"
            optionValue="factory_code"
            [options]="symbolOptions"
            formControlName="symbol"
            [placeholder]="'Select symbol'"
            [appendTo]="'body'"
          ></p-dropdown>
        </ng-template>
      </div>
    </div>
    <div class="col-6">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.GRADE" | transloco
        }}</span>
        <input
          *ngIf="isTradeConfirmation; else counterpartyGrade"
          type="text"
          pInputText
          formControlName="grade"
        />

        <ng-template #counterpartyGrade>
          <p-dropdown
            optionLabel="name"
            optionValue="grade_code"
            [options]="gradeOptions"
            formControlName="grade"
            [placeholder]="'Select grade'"
            [appendTo]="'body'"
          ></p-dropdown>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="grid mb-2">
    <div class="col-6">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.VOLUME" | transloco
        }}</span>
        <input type="number" pInputText formControlName="volume" />
      </div>
    </div>
    <div class="col-6">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.DEL_MONTH" | transloco
        }}</span>
        <p-calendar
          formControlName="deliveryMonth"
          [showIcon]="true"
          [icon]=""
          [readonlyInput]="true"
          dateFormat="M yy"
          [view]="'month'"
          [appendTo]="'body'"
        />
        <!-- <input type="text" pInputText formControlName="deliveryMonth" /> -->
      </div>
    </div>
  </div>
  <!-- <div *ngIf="!isTradeConfirmation" class="grid">
    <div class="col-12">
      <div class="flex flex-column">
        <span class="mb-2">{{
          "DASHBOARD.CREATE_NEW_SI.EMAIL_ADDRESS" | transloco
        }}</span>
        <input type="email" pInputText formControlName="emailAddress" />
      </div>
    </div>
  </div> -->
</div>
