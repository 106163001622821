<div class="grid mb-3">
  <div class="col-12">
    <span class="header"
      >{{ riskOverlapLabel }} {{ "MAP_PAGE.RISK_OVERLAPS" | transloco }}</span
    >
  </div>
</div>
<div class="grid risk-cards">
  <ng-container
    *ngIf="
      (isRiskAssigned$ | async) &&
        (eventStateService.isPlantationBySiLoaded$ | async) &&
        (eventStateService.isSourcingAreaLoaded$ | async);
      else riskDetailsLoading
    "
  >
    <div
      *ngFor="let riskOverlapDetail of riskOverlapDetails; index as i"
      class="col-4"
    >
      <p-card>
        <app-risk-overlap-details
          [riskOverlapDetail]="riskOverlapDetail"
        ></app-risk-overlap-details>
      </p-card>
    </div>
  </ng-container>
</div>

<ng-template #riskDetailsLoading>
  <div class="lg:col-4 col-6">
    <p-skeleton width="100%" height="25vh"></p-skeleton>
  </div>
  <div class="lg:col-4 col-6">
    <p-skeleton width="100%" height="25vh"></p-skeleton>
  </div>
  <div class="lg:col-4 col-6">
    <p-skeleton width="100%" height="25vh"></p-skeleton>
  </div>
</ng-template>
