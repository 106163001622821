<div #container>
  <ng-container [ngSwitch]="traceabilityStateService.currentScreen$ | async">
    <ng-container *ngSwitchDefault>
      <app-dashboard></app-dashboard>
    </ng-container>
    <ng-container *ngSwitchCase="screens.MAP_PAGE">
      <app-map-page></app-map-page>
    </ng-container>
  </ng-container>
  <p-toast [life]="5000000"></p-toast>
</div>
