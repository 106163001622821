import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import {
  INITIAL_PAGED_DATA_STATE,
  Settings,
  SiSummary,
  TableFilters,
  TraceabilityData,
} from 'src/app/models/traceability-state.model';
import { FormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  combineLatest,
  debounce,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TableTypeEnum } from 'src/app/enums/table-types.enum';
import { TraceabilityDataKeyEnum } from 'src/app/enums/table-data-keys.enum';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateNewSiModalComponent } from '../create-new-si-modal/create-new-si-modal.component';
import { LinkPlantationModalComponent } from '../link-plantation-modal/link-plantation-modal.component';
import { Renderer2 } from '@angular/core';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { GenerateDdsModalComponent } from '../generate-dds-modal/generate-dds-modal.component';
import { DownloadOptionsEnum } from 'src/app/enums/download-options.enum';
import { DownloadService } from 'src/app/services/download.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import {
  INITIAL_TRACEABILITY_TABLE_PARAMS,
  POLYGON_DATA_FILTER_OPTIONS,
} from 'src/app/constants/traceability-table.const';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import {
  COUNTRY_TABLE_COLUMNS,
  SI_NUMBER_TABLE_COLUMNS,
} from 'src/app/constants/table-columns.const';
import { CreateNewSiOptionsEnum } from 'src/app/enums/create-new-options.enum';
import { UploadEudrPackageModalComponent } from '../upload-eudr-package-modal/upload-eudr-package-modal.component';
import { EmailToCounterpartyComponent } from '../email-to-counterparty/email-to-counterparty.component';

@Component({
  selector: 'app-traceability-table',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    RiskIconComponent,
    TranslocoPipe,
  ],
  templateUrl: './traceability-table.component.html',
  styleUrls: ['./traceability-table.component.scss'],
})
export class TraceabilityTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @Input() period = '';
  columns: ColumnDefinition[] = SI_NUMBER_TABLE_COLUMNS;
  INITIAL_PAGED_DATA_STATE = INITIAL_PAGED_DATA_STATE;
  rowData: TraceabilityData[] = [];
  tableDataKey: TraceabilityDataKeyEnum = TraceabilityDataKeyEnum.SI_NUMBER;
  userType: string = '';

  selectedItems: TraceabilityData[] = [];
  selectedItemNames: string[] = [];
  globalFilterFields: string[] = [];
  filters: TableFilters = {};
  selectedFilterValues: { [key: string]: any } = {};
  filterOptions: { key: string; options: any }[] = [];
  filterChanged$ = new BehaviorSubject<{
    isChanged: boolean;
    isDropdown: boolean;
    field: string;
    values: any;
    function?: () => {};
  } | null>(null);
  sortProps: { sortField: string; sortOrder: number } = {
    sortField: '',
    sortOrder: 1,
  };
  sortPropsClone = {};
  private filterParams = '';

  pageRows: number = 10;
  totalRecords!: number;
  downloadOptions = DownloadOptionsEnum;

  isInitialLoad = true;
  tableViewOptions = [
    {
      label: 'SI Number',
      value: TableTypeEnum.SI_NUMBER,
    },
    {
      label: 'Country',
      value: TableTypeEnum.COUNTRY,
    },
  ];

  selectedTableView = TableTypeEnum.SI_NUMBER;

  destroyed$ = new Subject<void>();

  tableParams: any = INITIAL_TRACEABILITY_TABLE_PARAMS;
  isLoadPersistedFilters = false;
  isSettingsMapped = false;

  createNewSiOptionProps: any[] = [];
  downloadOptionProps = [
    {
      label: this.translateOptionLabel('POLYGON_DATA_FOR_EUIS'),
      loadingState: this.eventStateService.downloadEuisLoading$,
      downloadOption: DownloadOptionsEnum.POLYGON_DATA_EUIS,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('SI_INFORMATION'),
      downloadOption: DownloadOptionsEnum.SI_INFORMATION,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('FULL_RISK_REPORT'),
      loadingState: this.eventStateService.downloadFullRiskLoading$,
      downloadOption: DownloadOptionsEnum.FULL_RUSK_REPORT,
      hasDisabledCondition: false,
    },
    {
      label: this.translateOptionLabel('EUDR_PACKAGE'),
      loadingState: this.eventStateService.downloadEudrPackageLoading$,
      downloadOption: DownloadOptionsEnum.EUDR_PACKAGE,
      hasDisabledCondition: true,
    },
  ];

  createNewSiOptions = CreateNewSiOptionsEnum;

  polygonDataFilterOptions = POLYGON_DATA_FILTER_OPTIONS.options.map(
    (option) => ({
      ...option,
      label: this.translocoService.translate(option.label),
    })
  );

  get isListControlsDisabled() {
    return this.selectedItems.length <= 0;
  }

  get isEudrPackageDisabled() {
    return (
      this.selectedItems.length > 1
      // TODO: comment for now
      //  || !this.selectedItems[0]?.has_eudr_package
    );
  }

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    private dialogService: DialogService,
    public eventStateService: EventStateService,
    private renderer: Renderer2,
    private downloadService: DownloadService,
    private utilityService: UtilityService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initializeTraceabilityTable();
    this.initializeSelectedFilterValues();
    this.initializeTablePersistence();
    this.listenToTableRefresh();
    this.listenToFilterChange();
    this.initializeCreateNewSiOptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.traceabilityStateService.clearPagedTraceabilityData();
  }

  initializeCreateNewSiOptions() {
    this.traceabilityStateService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.createNewSiOptionProps = [
          {
            label: this.translateOptionLabel(
              'CREATE_NEW_SI.SELECT_TRADE_CONFIRMATION'
            ),
            option: CreateNewSiOptionsEnum.TRADE_CONFIRMATION,
          },
          ...(userInfo?.company.type === 'Consumer'
            ? []
            : [
                {
                  label: this.translateOptionLabel(
                    'CREATE_NEW_SI.SELECT_COUNTERPARTY'
                  ),
                  option: CreateNewSiOptionsEnum.COUNTERPARTY,
                },
              ]),
        ];
        this.userType = userInfo?.company.type || '';
      });
  }

  initializeTablePersistence() {
    combineLatest([
      this.traceabilityStateService.selectedTraceabilityData$,
      this.eventStateService.traceabilityTableParams$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([selectedData, persistedParams]) => {
        if (selectedData) {
          this.selectedItems = selectedData;
          this.selectedItemNames = selectedData.map(
            (selectedData) => selectedData[this.tableDataKey]!
          );
        }

        if (persistedParams) {
          this.isLoadPersistedFilters = true;
          persistedParams.tableParams.page = 0;
          this.tableParams = persistedParams.tableParams;
          this.filterParams = this.tableParams.filter;
          this.selectedFilterValues = persistedParams.filtersState;
          this.sortProps = persistedParams.sortProps;
        }
      });
  }

  initializeTraceabilityTable() {
    this.globalFilterFields = this.columns.map((c) => c.field);
    this.initializeTraceabilityTableFilters();

    this.traceabilityStateService.settings$
      .pipe(
        switchMap((settings) =>
          this.traceabilityStateService.pagedTraceabilityData$.pipe(
            tap((data) => {
              if (data?.results?.length && !this.isSettingsMapped) {
                this.mapSettingsToRow(settings);
              }
            })
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((data) => {
        this.mapSummaryToSi(data!.results!);
        this.rowData = data!.results!;
        this.totalRecords = data!.count;
        this.eventStateService.isTraceabilityTableLoading =
          data === INITIAL_PAGED_DATA_STATE;
        if (this.selectedItems.length) {
          this.selectedItems.forEach((item) => {
            this.rowData.forEach((rowData) => {
              if (rowData.si_number === item.si_number) {
                rowData.checked = true;
              }
            });
          });
        }
      });
  }

  mapSettingsToRow(settings?: Settings) {
    const siPageMapping = settings?.mapping?.si_page as any;

    if (siPageMapping) {
      this.columns.forEach((column) => {
        if (siPageMapping[column.field]) {
          column.field = siPageMapping[column.field];
        }
      });
    }

    this.isSettingsMapped = true;
  }

  mapSummaryToSi(data: TraceabilityData[]) {
    if (data?.length) {
      const siList = this.utilityService.getSiListArray(data);
      this.eventStateService.isSiSummariesLoaded = false;
      this.traceabilityStateService.getSiSummaries(siList);
      this.traceabilityStateService.siSummaries$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((summaries) => {
          if (summaries?.length) {
            this.rowData = [
              ...this.rowData.map((data) => {
                const siWithRisks = summaries.filter(
                  (summary) =>
                    data.si_number === summary.si_numb &&
                    summary.risks &&
                    Object.entries(summary.risks)?.length
                );
                const mappedSummary = summaries.find(
                  (summary) => summary.si_numb === data.si_number
                );
                return {
                  ...data,
                  risks: this.extractRisks(siWithRisks),
                  summary: mappedSummary,
                };
              }),
            ];
          }
        });
    }
  }

  extractRisks(summaries: SiSummary[]): string[] {
    const rowDataRisks: string[] = [];

    summaries.forEach((summary) => {
      this.checkAndPushRisk(rowDataRisks, summary, RiskTypesEnum.WDPA);
      this.checkAndPushRisk(
        rowDataRisks,
        summary,
        RiskTypesEnum.TREE_COVER_LOSS
      );
    });

    return rowDataRisks;
  }

  checkAndPushRisk(
    rowDataRisks: string[],
    summary: SiSummary,
    riskType: RiskTypesEnum
  ) {
    if (!rowDataRisks.includes(riskType) && summary.risks[riskType]) {
      rowDataRisks.push(riskType);
    }
  }

  initializeTraceabilityTableFilters() {
    this.traceabilityStateService.getSiPageFilters();
    this.traceabilityStateService.siPageTableFilters$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.filters = res!;
        for (const key in this.filters) {
          // TODO: move logic to data layer service
          let delivery_month = key === 'delivery_month';
          let options = this.filters[key]?.options.map((option) => ({
            label: delivery_month ? this.formatDate(option) : option,
            value: delivery_month ? this.formatDate(option) : option,
          }));
          this.filterOptions.push({ key: key, options: options });
        }
      });
  }

  initializeSelectedFilterValues() {
    // Assuming 'columns' is already defined or initialized before this method is called
    this.columns.forEach((column) => {
      this.selectedFilterValues[column.field] = [];
    });
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  }

  listenToTableRefresh() {
    merge(
      this.eventStateService.isNewSiCreated$,
      this.eventStateService.isPlantationLinked$
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.lazyLoadTraceabilityTable();
      });
  }

  listenToFilterChange() {
    this.filterChanged$
      .pipe(debounce((filter) => (!filter?.isDropdown ? timer(1000) : of(0))))
      .subscribe((filter) => {
        if (filter?.isChanged) {
          this.processFilterChange(
            filter?.field,
            this.selectedFilterValues[filter?.field]
          );
          filter?.function?.apply(filter.values!);
        }
      });
  }

  getFilterOptions(field: string) {
    if (field === 'has_plantation_data') {
      return this.polygonDataFilterOptions;
    }

    let foundObject = this.filterOptions.find((obj) => obj.key === field);
    if (field === 'counterparty' && !foundObject) {
      foundObject = this.filterOptions.find((obj) => obj.key === 'producer');
    }
    return foundObject ? foundObject.options : [];
  }

  onFilterChange(field: string, values: any, filter: any, isDropdown = false) {
    this.selectedFilterValues[field] = values;
    this.filterChanged$.next({
      isChanged: true,
      isDropdown: isDropdown,
      field: field,
      values: values,
      function: filter,
    });
  }

  onClearFilter(field: string, filter_name: string) {
    this.selectedFilterValues[field] = [];
    this.dt.clearFilterValues();
    this.onApplyFilter(field, filter_name);
  }

  // TODO: refactor
  onApplyFilter(field: string, filter_name: string) {
    this.processFilterChange(filter_name, this.selectedFilterValues[field]);
  }

  processFilterChange(field: string, values: string[]) {
    const paramRegex = new RegExp(`&${field}=[^&]*`, 'g');
    // Appends value if same filter field
    if (this.filterParams.includes(field)) {
      this.filterParams = this.filterParams.replace(
        paramRegex,
        this.getConstructedParams(field, values)
      );
    } else {
      this.filterParams += this.getConstructedParams(field, values);
    }

    if (field === 'has_plantation_data' && typeof values === 'boolean') return;

    // Removes filter params on clear
    if (!values.length) {
      this.filterParams = this.filterParams.replace(paramRegex, '');
      this.lazyLoadTraceabilityTable();
    }

    // this.hideFilterOverlay();
  }

  hideFilterOverlay() {
    const overlay = document.querySelector('.p-column-filter-overlay');
    if (overlay) {
      this.renderer.setStyle(overlay, 'display', 'none');
    }
  }

  getConstructedParams(field: string, values: string[]) {
    if (field === 'has_plantation_data') {
      return `&${field}=` + values;
    }

    return values.length > 0 ? `&${field}=` + values.join(',') : '';
  }

  getFilterClass(value: string | string[]) {
    return typeof value === 'boolean' || value?.length > 0
      ? 'active-filter'
      : 'inactive-filter';
  }

  lazyLoadTraceabilityTable(event?: TableLazyLoadEvent) {
    this.eventStateService.isTraceabilityTableLoading = true;

    if (!this.isLoadPersistedFilters) {
      this.customSort(event);
      this.tableParams.pageSize = event?.rows ?? 10;
      this.tableParams.page = event?.first ?? 0;
      this.tableParams.searchTerm = event?.globalFilter;
      this.tableParams.filter = this.filterParams;
    }
    this.isLoadPersistedFilters = false;
    this.traceabilityStateService.getSiPage(this.tableParams);
  }

  onClearAllClicked() {
    this.selectedItems = [];
    this.selectedItemNames = [];
  }

  onSelectedTableViewChange() {
    this.eventStateService.isTraceabilityTableLoading = true;
    this.selectedItemNames = [];
    this.selectedItems = [];

    this.traceabilityStateService.clearPagedTraceabilityData();
    if (this.selectedTableView === TableTypeEnum.SI_NUMBER) {
      this.columns = SI_NUMBER_TABLE_COLUMNS;
      this.traceabilityStateService.getSiPage(this.tableParams);
      this.traceabilityStateService.getSiPageFilters();
      this.tableDataKey = TraceabilityDataKeyEnum.SI_NUMBER;
      return;
    }

    this.columns = COUNTRY_TABLE_COLUMNS;
    this.traceabilityStateService.getCountryPage();
    this.tableDataKey = TraceabilityDataKeyEnum.COUNTRY;
  }

  customSort(event?: TableLazyLoadEvent) {
    let sortParam = 'si_buyer__buyer_name';
    if (event?.sortField) {
      switch (event?.sortField) {
        case 'counterparty':
          sortParam = 'si_buyer__buyer_name';
          break;
        case 'delivery_datetime':
          sortParam = 'si_ship_dt';
          break;
      }
      this.tableParams.ordering =
        event?.sortOrder === -1 ? '-' + sortParam : sortParam;
      this.eventStateService.traceabilityTableParams = {};
      this.sortPropsClone = {
        sortField: event.sortField.toString(),
        sortOrder: event.sortOrder!,
      };
    }
  }

  applyFilterGlobal(event: Event, filterType: string) {
    this.dt.filterGlobal((event.target as HTMLInputElement).value, filterType);
  }

  onShowMap() {
    this.traceabilityStateService.setSelectedTraceabilityData(
      this.selectedItems
    );
    this.traceabilityStateService.setCurrentScreen(ScreenEnum.MAP_PAGE);
    this.eventStateService.traceabilityTableParams = {
      tableParams: { ...this.tableParams },
      filtersState: { ...this.selectedFilterValues },
      sortProps: { ...this.sortPropsClone },
    };
  }

  onGenerateDdsClicked() {
    this.dialogService.open(GenerateDdsModalComponent, {
      width: '50%',
      styleClass: 'dds-modal',
      header: this.translocoService.translate(
        'DASHBOARD.DDR.DUE_DILIGENCE_REPORT'
      ),
      data: this.selectedItems,
    });
  }

  onDownloadClicked(downloadOption: DownloadOptionsEnum) {
    switch (downloadOption) {
      case DownloadOptionsEnum.POLYGON_DATA_EUIS:
        this.eventStateService.downloadEuisLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      case DownloadOptionsEnum.SI_INFORMATION:
        this.downloadService.downloadSiInformation(this.selectedItems);
        break;
      case DownloadOptionsEnum.FULL_RUSK_REPORT:
        this.eventStateService.downloadFullRiskLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      case DownloadOptionsEnum.EUDR_PACKAGE:
        this.eventStateService.downloadEudrPackageLoading = true;
        this.downloadService.download(downloadOption, this.selectedItems);
        break;
      default:
        break;
    }
  }

  onHeaderCheckboxToggle(event: any) {}

  onSelectedItemRemove(dataKey: string) {
    const deletedIndex = this.selectedItems.findIndex(
      (p) => p[this.tableDataKey] === dataKey
    );

    const tempSelectedsi = [...this.selectedItems];
    tempSelectedsi.splice(deletedIndex, 1);

    this.selectedItems = [...tempSelectedsi];
  }

  onUploadClicked() {}

  onSelectionChange(data: TraceabilityData[]) {
    this.selectedItemNames = data.map((data) => data[this.tableDataKey]!);
    const selectedItemKeys = [...this.selectedItemNames];
    this.rowData.forEach((row) => {
      if (selectedItemKeys.includes(row[this.tableDataKey]!)) {
        row.checked = true;
        return;
      }

      row.checked = false;
    });
  }

  onCreateNewSiClicked(option: CreateNewSiOptionsEnum) {
    this.dialogService.open(CreateNewSiModalComponent, {
      width: '50%',
      header: this.translocoService.translate(
        'DASHBOARD.CREATE_NEW_SI.CREATE_NEW_SI'
      ),
      data: option,
    });
  }

  onLinkPlantationClicked(rowData: TraceabilityData) {
    this.dialogService.open(LinkPlantationModalComponent, {
      width: '80%',
      header: `${this.translocoService.translate(
        'DASHBOARD.SI_LINKING.LINK_PLANTATION_TO_SI'
      )} ${rowData.si_number}`,
      data: { rowData: rowData, period: this.period },
    });
  }

  onUploadEudrPackage(rowData: TraceabilityData, has_eudr_package: boolean) {
    const ref: DynamicDialogRef = this.dialogService.open(UploadEudrPackageModalComponent, {
      width: '60%',
      header: `${this.translocoService.translate(
        'DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOAD_DOCUMENTS'
      )}`,
      data: { rowData: rowData, has_eudr_package, userType: this.userType },
    });

    ref.onClose.subscribe((result) => {
      this.lazyLoadTraceabilityTable();
    });
  }

  translateOptionLabel(label: string) {
    return this.translocoService.translate(`DASHBOARD.${label}`);
  }

  onRowEditInit(rowData: TraceabilityData, index: number) {}
  onEdit(rowData: TraceabilityData, index: number) {}
  onDelete(rowData: TraceabilityData, index: number) {}
  onRowEditCancel(rowData: TraceabilityData, index: number) {}
  onRowEditSave(rowData: TraceabilityData) {}

  onEmailToCounterparty(rowData: TraceabilityData) {
    this.dialogService.open(EmailToCounterpartyComponent, {
      width: '50%',
      header: this.translocoService.translate(
        'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL'
      ),
      data: { rowData: rowData, period: this.period },
    });
  }

  // modifySiDetails(rowData: TraceabilityData) {
  //   this.dialogService.open(EmailToCounterpartyComponent, {
  //     width: '50%',
  //     header: this.translocoService.translate(
  //       'DASHBOARD.ACTION_BUTTONS.SEND_SI_EMAIL'
  //     ),
  //     data: { rowData: rowData, period: this.period },
  //   });
  // }
}
