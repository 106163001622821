<div class="flex flex-column">
  <div class="flex flex-column my-4 pl-4">
    <span class="text-xl">{{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOAD_EUDR_DOCUMENTS" | transloco
      }}</span>
  </div>
  <div class="flex flex-column pl-4">
    <span class="text-xl">{{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.SELECTED_SI" | transloco }}<span
        class="si-number">{{siNumber}}</span></span>
    <span class="text-lg py-3">{{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOAD_SHIPPING_DOCUMENTS" |
      transloco }}</span>
  </div>
  <div class="flex justify-content-center align-items-center flex-column mx-4">
    <div class="flex flex-column justify-content-center align-items-center w-full mb-2 drop-zone"
      (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
      <button pButton type="button" label="Browse File" icon="pi pi-upload" class="p-button-success"
        (click)="fileInput.click()"></button>
      <span class="pt-2">{{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.DRAG_DROP_FILES" | transloco
        }}</span>
      <input type="file" #fileInput class="input-file" (change)="onFileSelected($event)" multiple />
    </div>
    <div class="w-full">
        <div class="flex justify-content-center align-items-center" *ngIf="isLoadingFiles">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
        <div *ngFor="let uploadedFile of uploadedFiles; let i = index"
          class="flex justify-content-between align-items-center mb-4 p-2">
          <div class="flex align-items-center">
            <span class="si-number material-symbols-outlined"> upload_file </span>
            <div class="flex flex-column ml-2">
              <span class="font-medium pb-1">{{ uploadedFile.filename || uploadedFile.file.name }}</span>
              <span>
                {{ uploadedFile.size ? uploadedFile.size : getFileSize(uploadedFile.file.size) }} • 
                <ng-container *ngIf="uploadedFile.loading; else uploadComplete">
                  {{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOADING" | transloco }}
                </ng-container>
                <ng-template #uploadComplete>
                  {{ "DASHBOARD.SI_NUMBER_TABLE_COLUMNS.UPLOAD_EUDR_PACKAGE.UPLOAD_COMPLETE" | transloco }}
                </ng-template>
              </span>
            </div>
          </div>
          <ng-container *ngIf="uploadedFile.loading; else deleteButton">
            <i class="pi pi-spin pi-spinner"></i>
          </ng-container>
          <ng-template #deleteButton>
            <span class="material-symbols-outlined delete-btn" (click)="deleteFile(i)"> delete </span>
          </ng-template>
        </div>
    </div>
  </div>
  <div class="flex justify-content-end flex-wrap mt-3 px-6 py-2 border-top-1 border-500" style="width: 100%;">
    <button pButton label="Back" class="p-button-outlined mr-2" (click)="closeModal()"></button>
    <button pButton label="Done" class="p-button-success" (click)="closeModal()"></button>
  </div>
  <p-confirmDialog class="confirm-modal"></p-confirmDialog>
</div>