import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import {
  SiSummary,
  TraceabilityData,
} from 'src/app/models/traceability-state.model';
import { DdrDownloadPayload } from 'src/app/models/ddr-download.model';
import { DownloadService } from 'src/app/services/download.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-generate-dds-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    ReactiveFormsModule,
    RiskIconComponent,
    TranslocoPipe,
    FormsModule
  ],
  templateUrl: './generate-dds-modal.component.html',
  styleUrls: ['./generate-dds-modal.component.scss'],
})
export class GenerateDdsModalComponent implements OnInit, OnDestroy {
  INITIAL_PAGE_SUB_HEADER =
    'Please kindly fill in the form and confirm your details below. Afterward, you may download it as a PDF for your DDS reporting.';
  FINAL_PAGE_SUB_HEADER =
    'You may download it as a PDF for your DDS reporting.';

  isFinalPage: boolean = false;
  qrData = 'https://agridence.com/';

  ddsFormGroup!: FormGroup;
  riskTypes = RiskTypesEnum;
  primaryActionText = 'DASHBOARD.DDR.CONFIRM';

  destroyed$ = new Subject<void>();
  totalPlantations = 0;
  totalPoints = 0;
  totalPolygons = 0;
  totalHectarage = 0;
  totalHectarageFormatted: any;
  totalVolumeMt = 0;
  treeCoverLossTotal = 0;
  wdpaTotal = 0;

  headerShippingInstruction = [
    { title: 'Land Legality', property: 'country' },
    { title: 'Env. Protection', property: 'someOtherProperty' },
    { title: '3PR & FPIC', property: 'someOtherProperty' },
    { title: 'Labour Rights', property: 'someOtherProperty' },
    { title: 'Tax, AC, TCR', property: 'someOtherProperty' },
  ];


  headerRiskLayers = [
    { title: 'Tree Cover Loss', property: 'country' },
    { title: 'SBTN', property: 'someOtherProperty' },
    { title: 'WDPA', property: 'someOtherProperty' },
    { title: 'Thai Forest Cover 2020', property: 'someOtherProperty' },
    { title: 'Thai Prot. Areas', property: 'someOtherProperty' },
    { title: 'Thai Res. Forest', property: 'someOtherProperty' },
    { title: 'Forest Area, Indonesia', property: 'someOtherProperty' },
    { title: 'EU Forest Observatory', property: 'someOtherProperty' },
    { title: 'Planet', property: 'someOtherProperty' },
    { title: 'Mapping Quality', property: 'someOtherProperty' },
  ];

  harmonisedSystemOptions = [
    { label: '400110: HS Codes Of Natural rubber latex, whether or not pre-vulcanised', value: '400110' },
    { label: '40012: HS Codes Of Natural rubber in other forms', value: '40012' },
    { label: '40011010: HS Codes Classification of Prevulcanised', value: '40011010' },
    { label: '40011020: HS Codes Classification of Other than prevulcanised', value: '40011020' },
    { label: '40012100: HS Codes Classification of Smoked sheets', value: '40012100' },
    { label: '40012200: HS Codes Classification of Technically specified natural rubber (TSNR)', value: '40012200' },
  ];

  harmonisedSystemCodeValue = '40012200';

  sisWithTreeCoverLoss: SiSummary[] = [];
  sisWithWdpa: SiSummary[] = [];

  get subHeader() {
    return this.isFinalPage
      ? this.FINAL_PAGE_SUB_HEADER
      : this.INITIAL_PAGE_SUB_HEADER;
  }

  get isSummaryItem1Checked() {
    return this.ddsFormGroup.get('isSummaryItem1Checked') as FormControl;
  }

  get isSummaryItem2Checked() {
    return this.ddsFormGroup.get('isSummaryItem2Checked') as FormControl;
  }

  get eoriNumber() {
    return this.ddsFormGroup.get('eoriNumber') as FormControl;
  }

  get harmonisedSystemCode() {
    return this.ddsFormGroup.get('harmonisedSystemCode') as FormControl;
  }

  get tradeName() {
    return this.ddsFormGroup.get('tradeName') as FormControl;
  }

  get scientificName() {
    return this.ddsFormGroup.get('scientificName') as FormControl;
  }

  get quantity() {
    return this.ddsFormGroup.get('quantity') as FormControl;
  }

  get factoryName() {
    return this.ddsFormGroup.get('factoryName') as FormControl;
  }

  get factoryAddress() {
    return this.ddsFormGroup.get('factoryAddress') as FormControl;
  }

  get processorName() {
    return this.ddsFormGroup.get('processorName') as FormControl;
  }

  get processDate() {
    return this.ddsFormGroup.get('processDate') as FormControl;
  }

  get selectedSis(): TraceabilityData[] {
    return this.dialogConfig.data;
  }

  get riskOverlaps() {
    return [
      {
        riskType: RiskTypesEnum.TREE_COVER_LOSS,
        label: this.translocoService.translate('DASHBOARD.DDR.TREE_COVER_LOSS'),
        detailList: this.sisWithTreeCoverLoss,
      },
      {
        riskType: RiskTypesEnum.WDPA,
        label: this.translocoService.translate('DASHBOARD.DDR.PROTECTED_AREAS'),
        detailList: this.sisWithWdpa,
      },
    ];
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private downloadService: DownloadService,
    private utilityService: UtilityService,
    public eventStateService: EventStateService,
    private decimalPipe: DecimalPipe,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.initializeDdsFormGroup();
    this.initializeSiSummaries();
    this.listenToDownloadEvent();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  listenToDownloadEvent() {
    this.eventStateService.isDdrDownloaded$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isDownloaded) => {
        if (isDownloaded) {
          this.dialogRef.close();
        }
      });
  }

  initializeSiSummaries() {
    const summaries: SiSummary[] = [
      ...this.selectedSis.map((selectedSi) => selectedSi.summary!),
    ];

    this.sisWithTreeCoverLoss = [
      ...summaries.filter(
        (summary) =>
          summary?.risks && summary?.risks[RiskTypesEnum.TREE_COVER_LOSS]
      ),
    ];

    this.sisWithWdpa = [
      ...summaries.filter(
        (summary) => summary?.risks && summary?.risks[RiskTypesEnum.WDPA]
      ),
    ];

    this.totalPoints = this.utilityService.calculateSum(
      summaries,
      'total_points'
    );

    this.totalPolygons = this.utilityService.calculateSum(
      summaries,
      'total_polygons'
    );

    this.totalPlantations = this.totalPoints + this.totalPolygons;

    this.totalHectarage = this.utilityService.calculateSum(
      summaries,
      'total_area'
    );

    this.totalHectarageFormatted = this.decimalPipe.transform(this.totalHectarage, '1.2-2');

    this.totalVolumeMt = this.utilityService.calculateSum(
      this.selectedSis,
      'si_weight'
    );

    this.treeCoverLossTotal = this.utilityService.calculateSum(
      [
        ...this.sisWithTreeCoverLoss.map(
          (si) => si.risks[RiskTypesEnum.TREE_COVER_LOSS]
        ),
      ],
      'overlapAreaPerc'
    );

    this.wdpaTotal = this.utilityService.calculateSum(
      [...this.sisWithWdpa.map((si) => si.risks[RiskTypesEnum.WDPA])],
      'overlapAreaPerc'
    );

    const totalVolumeKg = this.totalVolumeMt * 1000;
    this.quantity.setValue(this.decimalPipe.transform(totalVolumeKg, '1.0-0'));
  }

  initializeDdsFormGroup() {
    this.ddsFormGroup = this.formBuilder.group({
      isSummaryItem1Checked: [false],
      isSummaryItem2Checked: [false],
      harmonisedSystemCode: this.harmonisedSystemCodeValue,
      tradeName: [
        this.translocoService.translate('DASHBOARD.DDR.NATURAL_RUBBER'),
      ],
      scientificName: [
        this.translocoService.translate('DASHBOARD.DDR.HEVEA_BRASILLIENSIS'),
      ],
      quantity: [''],
      factoryName: ['', Validators.required],
      factoryAddress: ['', Validators.required],
      processorName: [''],
      processDate: new Date()
    });
    console.log(this.ddsFormGroup)
  }

  onHarmonisedSystemCodeChange(event: any) {
    console.log(event)
    this.ddsFormGroup.get('harmonisedSystemCode')?.setValue(event.value);
  }

  onConfirmClicked() {
    if (this.isFinalPage) {
      this.eventStateService.isDownloadDdrLoading = true;
      this.downloadService.downloadDdr(this.getDdrDownloadPayload());
    }

    this.isFinalPage = true;
    this.primaryActionText = 'DASHBOARD.DDR.DOWNLOAD';
  }

  getDdrDownloadPayload(): DdrDownloadPayload {
    return {
      si_numb: this.selectedSis.map((si) => si.si_number!),
      total_hectarage: this.totalHectarage,
      tree_cover_loss: this.treeCoverLossTotal,
      protected_areas: this.wdpaTotal,
      summary_data_collected: this.isSummaryItem1Checked.value,
      summary_produced: this.isSummaryItem2Checked.value,
      processor_name: this.processorName.value,
      process_date: this.datePipe.transform(this.processDate.value, 'yyyy-MM-dd')!,
      polygons: this.totalPolygons,
      gps_point: this.totalPoints,
      factory_name: this.factoryName.value,
      factory_address: this.factoryAddress.value,
      commodity_info: [
        {
          title: 'Harmonised System code',
          value: this.ddsFormGroup.get('harmonisedSystemCode')?.value,
        },
        {
          title: 'Trade Name',
          value: this.tradeName.value,
        },
        {
          title: 'Scientific Name',
          value: this.scientificName.value,
        },
        {
          title: 'Quantity (KG)',
          value: this.quantity.value,
        },
      ],
      refresh: true,
    };
  }

  onBackClicked() {
    this.primaryActionText = 'DASHBOARD.DDR.CONFIRM';

    this.isFinalPage = false;
  }
}
