import { Injectable } from '@angular/core';
import csvDownload from 'json-to-csv-export';
import { TraceabilityData } from '../models/traceability-state.model';
import { DashboardService } from './data-service/dashboard.service';
import { UtilityService } from './utility.service';
import * as saveAs from 'file-saver';
import { EventStateService } from './state-service/event-state.service';
import { DownloadOptionsEnum } from '../enums/download-options.enum';
import JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { DdrDownloadPayload } from '../models/ddr-download.model';
import { SI_NUMBER_TABLE_COLUMNS } from '../constants/table-columns.const';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  filesToZip$ = new Subject<{ file: Blob | null; fileName: string }>();
  filesToZip: { file: Blob | null; fileName: string }[] = [];
  zipEndpointResultLength = 0;

  constructor(
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private eventStateService: EventStateService
  ) {
    this.filesToZip$.subscribe((files) => {
      this.filesToZip.push(files);
      if (this.filesToZip.length === 3 + this.zipEndpointResultLength) {
        const zip = new JSZip();
        this.filesToZip.forEach((file) => {
          if (file.file) {
            zip.file(file.fileName, file.file);
          }
        });

        zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          saveAs(content, 'EUDRPackage.zip');
        });
      }
    });
  }

  downloadSiInformation(rowData: TraceabilityData[]) {
    const data = rowData.map((data) => ({
      'SI Number': data.si_number || '-',
      Risks: data.risks || '-',
      'Polygon Data': data.has_plantation_data || '-',
      'Contract Number': data.contract_number || '-',
      'Reference Number': data.buyer_ref_numb || '-',
      'PO Number': data.po_number || '-',
      Counterparty: data.counterparty || '-',
      Symbol: data.factory_code || '-',
      Grade: data.grade_name || '-',
      Volume: data.si_weight || '-',
      'Delivery Month': data.delivery_datetime || '-',
    }));

    const dataToConvert = {
      data: data,
      filename: 'SI Information',
      delimiter: ',',
      headers: SI_NUMBER_TABLE_COLUMNS.map((column) => column.displayName),
    };

    csvDownload(dataToConvert);
  }

  download(downloadOption: DownloadOptionsEnum, rowData: TraceabilityData[]) {
    const siList = this.utilityService.getSiListArray(rowData);

    this.dashboardService.download(downloadOption, siList).subscribe({
      next: (res) => {
        const fileName = this.utilityService.getFileNameFromHeaders(res);

        saveAs(res.body as Blob, fileName);
        switch (downloadOption) {
          case DownloadOptionsEnum.POLYGON_DATA_EUIS:
            this.eventStateService.downloadEuisLoading = false;
            break;
          case DownloadOptionsEnum.FULL_RUSK_REPORT:
            this.eventStateService.downloadFullRiskLoading = false;
            break;
          case DownloadOptionsEnum.EUDR_PACKAGE:
            this.eventStateService.downloadEudrPackageLoading = false;
            break;
          default:
            break;
        }
      },
    });
  }

  downloadDdr(payload: DdrDownloadPayload) {
    this.dashboardService.downloadDdr(payload).subscribe((res) => {
      saveAs(res.url, 'Due Diligence Report');
      this.eventStateService.isDownloadDdrLoading = false;
      this.eventStateService.isDdrDownloaded = true;
      this.utilityService.emitToast({
        isSuccess: true,
        message: 'Due diligence report downloaded successfully.',
      });
    });
  }
}
