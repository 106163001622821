<div class="flex">
  <!-- TODO: Temporary comment out -->
  <!-- <div class="flex mb-3 align-items-center">
    <span class="material-symbols-outlined mr-2 back-button view-all">
      arrow_back
    </span>
    <span class="font-semibold view-all">View all SI</span>
  </div> -->
</div>
<!-- TODO: Temporary comment out -->
<!-- <div class="flex mb-4">
  <p-card class="sourcing-area-card">
    <div class="flex">
      <span class="tab-content-header">Traceability Coverage</span>
    </div>
    <div class="flex justify-content-center align-items-center mt-5">
      <p-chart
        class="mr-3"
        type="doughnut"
        [height]="'12rem'"
        [width]="'12rem'"
        [responsive]="true"
        [data]="data"
        [options]="options"
        [plugins]="totalValuePlugin()"
      ></p-chart>
      <div class="flex flex-column">
        <div class="flex align-items-center mb-3">
          <div class="district-icon mr-2"></div>
          <span>District - 70%</span>
        </div>
        <div class="flex align-items-center mb-3">
          <div class="village-icon mr-2"></div>
          <span>Village - 70%</span>
        </div>
        <div class="flex align-items-center mb-3">
          <div class="collection-point-icon mr-2"></div>
          <span>Collection Point - 70%</span>
        </div>
        <div class="flex align-items-center mb-3">
          <div class="plantations-icon mr-2"></div>
          <span>Plantations - 70%</span>
        </div>
      </div>
    </div>
  </p-card>
</div> -->
<div class="flex">
  <p-card class="sourcing-area-card">
    <div class="flex justify-content-between mb-4">
      <span class="tab-content-header"
        >{{ "MAP_PAGE.SOURCING_AREAS" | transloco }}
      </span>
      <i
        (click)="toggleAllExpand()"
        class="pi tab-content-header clickable-icon"
        [ngClass]="
          isSourcingAreasExpanded
            ? 'pi-angle-double-up'
            : 'pi-angle-double-down'
        "
      ></i>
    </div>

    <ng-container *ngFor="let data of compositionData">
      <div
        *ngIf="!data.parent_id"
        class="flex justify-content-between align-items-center sourcing-area-header my-3"
      >
        <div class="flex align-items-center">
          <!-- TODO: Temporary comment out -->
          <!-- <div class="plantations-icon mr-2"></div> -->
          <span>{{ data.adm_name }} / {{ data.si_numb }}</span>
        </div>
        <div>
          <span class="mr-2">{{ data.composition_prct }}%</span>
          <i
            (click)="toggleSourcingAreasExpand(data)"
            class="pi chevron-icon clickable-icon"
            [ngClass]="data.isExpanded ? 'pi-angle-down' : 'pi-angle-up'"
          ></i>
        </div>
      </div>

      <ng-container *ngIf="data.isExpanded">
        <ng-container *ngFor="let adm2 of data.children">
          <div
            class="flex justify-content-between align-items-center ml-3 mb-1"
          >
            <div class="flex align-items-center">
              <span>{{ adm2.adm_name }}</span>
            </div>
            <div>
              <span class="mr-2">{{ adm2.composition_prct }}%</span>
              <i
                (click)="toggleSourcingAreasExpand(adm2)"
                class="pi chevron-icon clickable-icon"
                [ngClass]="adm2.isExpanded ? 'pi-angle-down' : 'pi-angle-up'"
              ></i>
            </div>
          </div>

          <ng-container *ngIf="adm2.isExpanded">
            <ng-container *ngFor="let adm3 of adm2.children; last as last">
              <div
                class="flex justify-content-between align-items-center ml-5 mb-1"
                [class.mb-3]="last"
              >
                <div class="flex align-items-center">
                  <span>{{ adm3.adm_name }}</span>
                </div>
                <div>
                  <span class="mr-4-5">{{ adm3.composition_prct }}%</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </p-card>
</div>
