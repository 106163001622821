<div class="flex">
  <span class="tab-content-header">{{
    "MAP_PAGE.SELECTED_SIS" | transloco
  }}</span>
</div>
<div *ngFor="let traceabilityData of selectedTraceabilityData; first as first">
  <div class="mb-4" [class.my-4]="first">
    <div class="flex justify-content-between">
      <div class="flex align-items-center">
        <p-checkbox
          [value]="traceabilityData"
          (onChange)="onSiChecked()"
          [binary]="true"
          [(ngModel)]="traceabilityData.checked"
        ></p-checkbox>
        <span
          class="si-name ml-3"
          (click)="onZoomPlantation(traceabilityData?.plantationData)"
          >{{ traceabilityData.si_number }}</span
        >
      </div>
      <div class="flex align-items-center">
        <!-- TODO: Temporary comment out -->
        <!-- <i class="pi pi-download clickable-icon download-icon"></i> -->
        <i
          class="pi chevron-icon ml-2 clickable-icon"
          [ngClass]="
            traceabilityData.isExpanded ? 'pi-chevron-down' : 'pi-chevron-right'
          "
          (click)="onToggleExpand(traceabilityData)"
        ></i>
      </div>
    </div>
    <div class="flex mt-2 si-details" *ngIf="traceabilityData.isExpanded">
      <div class="flex flex-column">
        <div class="my-1" *ngFor="let detail of siDetailFields">
          <ng-container
            *ngIf="detail.field !== 'plantationData'; else plantationData"
          >
            <span class="label"> {{ detail.label | transloco }}: </span>
            <span class="value">
              {{ traceabilityData[detail.field] || "-" }}
            </span>
          </ng-container>
          <ng-template #plantationData>
            <div class="flex">
              <span class="label mr-1"> {{ detail.label | transloco }}: </span>
              <div class="flex flex-column">
                <span *ngIf="!traceabilityData[detail.field]?.length">-</span>
                <span
                  *ngFor="let plantation of traceabilityData[detail.field]"
                  (click)="onZoomPlantation([plantation])"
                  (mouseenter)="onHoverPlantation(plantation)"
                  (mouseleave)="onHoverPlantationLeave()"
                  class="plantation mb-1 cursor-pointer"
                >
                  {{ plantation.plantation_name || "-" }}
                </span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
