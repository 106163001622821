<div class="secret-key-page">
    <div class="secret-key-component" *ngIf="!nextPage">
        <div class="flex justify-content-center align-items-center"
            style="height: 90vh; display: grid; grid-template-columns: 1fr auto 1fr;">
            <div></div>
            <div class="flex flex-column">
                <span class="text-xl"> {{ 'EXTERNAL_PAGE.SECRET_KEY' | transloco }}</span>
                <input pInputText type="text" placeholder="{{ 'EXTERNAL_PAGE.ENTER_SECRET_KEY' | transloco }}" [(ngModel)]="secretKey"
                    class="secret-input mt-2 text-lg">
                <button pButton class="confirm-btn mt-3 text-lg font-medium" (click)="checkValidity()"
                    [disabled]="eventStateService.isVerifyingSecretKeyLoading$ | async">
                    <ng-container *ngIf="!(eventStateService.isVerifyingSecretKeyLoading$ | async); else loading">
                        {{ 'EXTERNAL_PAGE.CONFIRM' | transloco }}
                    </ng-container>
                    <ng-template #loading>
                        <i class="pi pi-spin pi-spinner pr-1"></i> {{ 'EXTERNAL_PAGE.LOADING' | transloco }}
                    </ng-template>
                </button>
            </div>
            <div></div>
        </div>
        <div class="footer flex justify-content-center">
            {{ 'EXTERNAL_PAGE.POWERED_BY_AGRIDENCE' | transloco }}
        </div>
    </div>
    <div class="download-si-component" *ngIf="nextPage">
        <p-toolbar class="download-toolbar">
            <div class="p-toolbar-group-left">
                <img src="../../../assets/images/logo-white.svg" alt="agridence-logo" />
            </div>
            <div class="p-toolbar-group-right">
                <i class="pi pi-sign-out logout-btn text-2xl" (click)="logout()"></i>
            </div>
        </p-toolbar>
        <div class="px-4">
            <h2>{{ 'EXTERNAL_PAGE.DOWNLOAD_SI_INFORMATION' | transloco }}</h2>
            <div class="container p-4 flex flex-column">
                <span class="py-2 text-lg font-medium"> {{ 'EXTERNAL_PAGE.SI_NUMBER' | transloco }} <span>{{siNumber}}</span></span>
                <!-- <span class="pb-2 text-lg">Details of zip file: </span>
                <span class="pl-2 text-lg">&#8226; Geolocation Data</span>
                <span class="pl-2 text-lg">&#8226; DDR</span>
                <span class="pl-2 text-lg">&#8226; Shipping Documents</span> -->
                <button pButton class="p-button-success download-btn mt-3 py-3" (click)="downloadEUDRPackage()">
                    <ng-container *ngIf="!(eventStateService.isDownloadingExternalZipFileLoading$ | async); else loadingZipFile">
                        <i class="pi pi-download pr-2"></i> {{ 'EXTERNAL_PAGE.DOWNLOAD_EUDR' | transloco }}
                    </ng-container>
                    <ng-template #loadingZipFile>
                        <i class="pi pi-spin pi-spinner pr-2"></i> {{ 'EXTERNAL_PAGE.DOWNLOADING' | transloco }}
                    </ng-template></button>
                    
                <button pButton class="p-button-success download-btn mt-3 py-3" (click)="downloadGeoJsonFiles()">
                    <ng-container *ngIf="!(eventStateService.isDownloadingExternalJsonFileLoading$ | async); else loadingJsonFile">
                        <i class="pi pi-download pr-2"></i> {{ 'EXTERNAL_PAGE.DONWLOAD_GEOJSON' | transloco }}
                    </ng-container>
                    <ng-template #loadingJsonFile>
                        <i class="pi pi-spin pi-spinner pr-2"></i> {{ 'EXTERNAL_PAGE.DOWNLOADING' | transloco }}
                    </ng-template></button>
            </div>

        </div>
    </div>
</div>