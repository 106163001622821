import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { SiDetailsTabComponent } from './si-details-tab/si-details-tab.component';
import { SourcingAreaTabComponent } from './sourcing-area-tab/sourcing-area-tab.component';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-selected-si-details-summary',
  standalone: true,
  imports: [
    CommonModule,
    SiDetailsTabComponent,
    PrimengExportsModule,
    SourcingAreaTabComponent,
    ProgressBarSkeletonComponent,
    TranslocoPipe,
  ],
  templateUrl: './selected-si-details-summary.component.html',
  styleUrls: ['./selected-si-details-summary.component.scss'],
})
export class SelectedSiDetailsSummaryComponent implements OnDestroy {
  constructor(public eventStateService: EventStateService) {}

  ngOnDestroy(): void {
    this.eventStateService.isSourcingAreaLoaded = false;
  }
}
