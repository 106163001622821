import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { PrimengExportsModule } from '../primeng-exports.module';
import { ExternalService } from '../services/data-service/external.service';
import { AuthService } from '../services/auth.service';
import { EventStateService } from '../services/state-service/event-state.service';
import { saveAs } from 'file-saver';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-secret-key-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PrimengExportsModule,
    TranslocoPipe,
],
  templateUrl: './secret-key-page.component.html',
  styleUrls: ['./secret-key-page.component.scss']
})
export class SecretKeyPageComponent {
  private destroy$ = new Subject<void>();
  siNumber: any;
  secretKey: string = '';
  nextPage = false;

  constructor(
    private route: ActivatedRoute,
    private externalService: ExternalService,
    private authService: AuthService,
    public eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.siNumber = params['si_numb'];
    });
  }

  checkValidity() { 
    this.externalService.getVerifySiNumbSecretKey(this.siNumber, this.secretKey).subscribe(res => {
      if(res.valid) {
        this.nextPage = res.valid;
        this.authService.offPlatformSecretKey = this.secretKey;
      }
    })
  }

  downloadGeoJsonFiles(): void {
    if(this.authService.offPlatformSecretKey){
      this.externalService.downloadGeoJSONFiles(this.siNumber).subscribe(res => {
        const blob = new Blob([res], { type: 'application/json' });
        saveAs(blob, `${this.siNumber}_geojson_file.json`);
      });
    }
  }

  downloadEUDRPackage() {
    if (this.authService.offPlatformSecretKey) {
      this.externalService.downloadEudrPackage(this.siNumber).subscribe(res => {
        const blob = new Blob([res], { type: 'application/zip' });
        saveAs(blob, `${this.siNumber}_EUDR_package.zip`);
      });
    }
  }

  logout(){
    this.nextPage = false;
    this.authService.offPlatformSecretKey = null;
  }
}
