import { Injectable } from '@angular/core';
import { of, delay, Observable, map, tap } from 'rxjs';
import { DEFAULT_MAP_STYLE } from 'src/app/constants/map.const';
import {
  CompositionData,
  PlantationsBySi,
  RiskLayer,
} from 'src/app/models/traceability-state.model';
import { HttpClient } from '@angular/common/http';
import { parse } from 'wellknown';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../utility.service';
import { EventStateService } from '../state-service/event-state.service';

@Injectable({
  providedIn: 'root',
})
export class MapPageService {
  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
    private eventStateService: EventStateService
  ) {}

  getMapStyle() {
    return of(DEFAULT_MAP_STYLE).pipe(delay(2000));
  }

  getRiskLayers(): Observable<RiskLayer[]> {
    return this.http
      .get(`${environment.TRACEABILITY_API}getLayersForCompany/`)
      .pipe(
        map((res: any) => {
          const layers = Object.values(res.layers).map((layer: any) => ({
            ...layer,
            checked: true,
          }));
          return Object.values(layers);
        })
      )
      .pipe(delay(2000));
  }

  getPlantationsBySi(
    siList: string[],
    withRisk = true
  ): Observable<PlantationsBySi[]> {
    const siListQuery = this.utilityService.constructMultipleSiQuery(siList);

    return this.http
      .get<PlantationsBySi[]>(
        `${environment.TRACEABILITY_API}si/get-plantations-by-si/${siListQuery}&withRisks=${withRisk}`
      )
      .pipe(
        tap(() => {
          if (!siList?.length) {
            this.eventStateService.isPlantationBySiLoaded = true;
            return;
          }
        }),
        map((plantations) => {
          const parsedGeoDataPlantations = [
            ...plantations.map((plantation) => ({
              ...plantation,
              _geo: parse(plantation._geo as string),
            })),
          ];

          const modifiedPlantations: PlantationsBySi[] = [];

          parsedGeoDataPlantations.forEach((item) => {
            (item.si! as string[]).forEach((si) => {
              const newItem = { ...item, si: si };
              modifiedPlantations.push(newItem);
            });
          });

          return modifiedPlantations;
        })
      );
  }

  getCompositionData(siList: string[]) {
    const siListQuery = this.utilityService.constructMultipleSiQuery(siList);

    return this.http
      .get<CompositionData[]>(
        `${environment.TRACEABILITY_API}composition-data/${siListQuery}`
      )
      .pipe(
        tap(() => {
          this.eventStateService.isSourcingAreaLoaded = true;
        }),
        map((res) => {
          if (res?.length) {
            const modifiedData = res?.map((data) => ({
              ...data,
              isExpanded: false,
            })) as CompositionData[];
            return modifiedData;
          }

          return null;
        })
      );
  }
}
