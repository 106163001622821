import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TraceabilityShellComponent } from './traceability-shell/traceability-shell.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SecretKeyPageComponent } from './secret-key-page/secret-key-page.component';

const routes: Routes = [
  { path: '', component: TraceabilityShellComponent },
  { path: 'token/:token', component: TraceabilityShellComponent },
  { path: 'offplatformsiview/:si_numb', redirectTo: 'secret-key', pathMatch: 'full' },
  { path: 'offplatformsiview', component: SecretKeyPageComponent },
  {
    path: 'token/:token',
    children: [
      {
        path: 'language/:language',
        component: TraceabilityShellComponent,
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
