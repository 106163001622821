import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe } from '@jsverse/transloco';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { last, map, switchMap, tap } from 'rxjs';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService } from 'primeng/api';
import { UtilityService } from 'src/app/services/utility.service';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-upload-eudr-package-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    TranslocoPipe,
  ],
  templateUrl: './upload-eudr-package-modal.component.html',
  styleUrls: ['./upload-eudr-package-modal.component.scss']
})
export class UploadEudrPackageModalComponent {
  uploadedFiles: any[] = [];
  siNumber: string = '';
  siDetailId: string = '';
  rowData: any;
  isLoading: boolean = false;
  isLoadingFiles: boolean = true;
  userType = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private utilityService: UtilityService,
    private fireStorage: AngularFireStorage,
    private fireAuth: AngularFireAuth,
    private traceabilityStateService: TraceabilityStateService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.rowData = this.dialogConfig.data?.rowData;
    this.userType = this.dialogConfig.data?.userType;
    this.siNumber = this.rowData.producer === 'HG' ? this.rowData.si_number : this.rowData.sidetail_id;
    this.getFiles();
  }

  getFiles() {
    if (this.dialogConfig.data?.has_eudr_package) {
      this.traceabilityStateService.getEudrFiles(this.rowData.si_number, this.rowData.sidetail_id).subscribe((files: any[]) => {
        const fileSizePromises = files.map(file => {
          const filePath = `${environment.STORAGE_BUCKET.replace('{siNumber}', this.siNumber)}${file.filename || file.name}`;
          return this.getFileSizeFromFirebase(filePath).then(size => {
            file.size = size;
            return file;
          });
        });
        Promise.all(fileSizePromises).then(updatedFiles => {
          this.uploadedFiles = updatedFiles; 
          this.isLoadingFiles = false;
        });
      });
    } else {
      this.uploadedFiles = [];
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.add('drag-over');
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFiles(input.files);
    }
  }

  uploadFile(file: File, filePath: string): AngularFireUploadTask {
    const token = sessionStorage.getItem('Firebase_Token') || ''; 
    return this.fireStorage.upload(filePath, file, {
      customMetadata: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  handleFiles(files: FileList) {
    const token = sessionStorage.getItem('Firebase_Token') || ''; 

    this.fireAuth.signInWithCustomToken(token).then(() => {
      const bucketPath = this.rowData.producer === 'HG' 
        ? `${environment.STORAGE_BUCKET.replace('{siNumber}', this.siNumber)}` 
        : `${environment.STORAGE_BUCKET_V2.replace('{siNumber}', this.siNumber)}`;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name; 
        const filePath = `${bucketPath}/${fileName}`; 

        this.uploadedFiles.push({ file, loading: true });

        const task = this.uploadFile(file, filePath);
        task.snapshotChanges().pipe(
          last(),
          switchMap(() => this.getDownloadLink(filePath))
        ).subscribe({
          next: (snapshot) => {
            if(!this.dialogConfig.data?.has_eudr_package) {
              const payload = {
                si: this.rowData.si_number,
                sidetail_id: this.rowData.sidetail_id
              }
              this.traceabilityStateService.setEudrFiles(payload);
            }
            this.uploadedFiles[this.uploadedFiles.length - 1].loading = false;
          },
          error: (error) => {
            console.error('Upload error:', error);
            this.utilityService.emitToast({
              message: 'There was an error uploading the file. Please try again.',
              isSuccess: false,
            });
            this.uploadedFiles.pop();
          }
        });
      }
    }).catch((error) => {
      console.error('Authentication error:', error);
    });
  }

  getDownloadLink(filePath: string) {
    return this.fireStorage.ref(filePath).getDownloadURL().pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteFile(index: number) {
    const fileToDelete = this.uploadedFiles[index];
    const filePath = `${environment.STORAGE_BUCKET.replace('{siNumber}', this.siNumber)}/${fileToDelete.name || fileToDelete.filename}`;

    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${fileToDelete.name || fileToDelete.filename}?`,
      header: 'Confirmation',
      accept: () => {
        this.deleteFileFromFirebase(filePath).then(() => { 
          this.utilityService.emitToast({
            message: 'File deleted successfully.',
            isSuccess: true,
          });
        });
        this.uploadedFiles.splice(index, 1);
      },
      reject: () => {
        console.log('Delete action cancelled');
      }
    });
  }

  deleteFileFromFirebase(filePath: string): Promise<void> {
    const storageRef = this.fireStorage.ref(filePath);
    return storageRef.delete().toPromise().then(() => {
      console.log('File deleted successfully');
    }).catch((error) => {
      console.error('Error deleting file:', error);
    });
  }

  getFileSize(size: number): string {
    if (size < 1024) {
        return size + ' B';
    } else if (size < 1048576) {
        return (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1073741824) {
        return (size / 1048576).toFixed(2) + ' MB';
    } else {
        return (size / 1073741824).toFixed(2) + ' GB';
    }
  }
  getFileSizeFromFirebase(filePath: string): Promise<string> {
    return this.fireStorage.ref(filePath).getMetadata().toPromise().then(metadata => {
      return this.getFileSize(Number(metadata.size));  
    });
  }

  closeModal() {
    this.dialogRef.close()
  }
}
