<div class="flex my-4 px-4">
  <span>{{ "DASHBOARD.SI_LINKING.SUB_HEADER" | transloco }}</span>
</div>
<div class="flex flex-column align-items-center mb-6">
  <div class="w-5">
    <p-button
      styleClass="mr-4 w-full mt-4"
      (onClick)="onRedirectToPmdClicked()"
    >
      <span class="text-center w-full">
        {{ "DASHBOARD.SI_LINKING.LINK_VIA_PMD_REDIRECT" | transloco }}
      </span>
    </p-button>
  </div>
  <!-- <div class="w-5">
    <p-button styleClass="secondary-button mr-4 w-full mt-4">
      <span class="text-center w-full">
        {{ "DASHBOARD.SI_LINKING.LINK_VIA_EXISTING_DATA_SOURCE" | transloco }}
      </span>
    </p-button>
  </div>
  <div class="w-5">
    <p-button [outlined]="true" styleClass="mr-4 w-full mt-4">
      <span class="text-center w-full mr-2">
        {{ "DASHBOARD.SI_LINKING.LINK_VIA_FILE_UPLOAD" | transloco }}
      </span>
      <i class="pi pi-chevron-down"></i>
    </p-button>
  </div> -->
</div>
