export const INITIAL_TRACEABILITY_TABLE_PARAMS = {
  pageSize: 10,
  page: 10,
};

export const POLYGON_DATA_FILTER_OPTIONS = {
  key: 'has_plantation_data',
  options: [
    { label: 'DASHBOARD.HAS_POLYGON_DATA', value: true },
    { label: 'DASHBOARD.NO_POLYGON_DATA', value: false },
  ],
};
