<div id="map">
  <ng-container
    *ngIf="
      !(traceabilityStateService.mapStyle$ | async)?.length;
      else layerControl
    "
  >
    <app-progress-bar-skeleton [width]="'100%'" [height]="'100%'">
    </app-progress-bar-skeleton>
  </ng-container>
  <ng-template #layerControl>
    <app-layers-map-control></app-layers-map-control>
  </ng-template>
</div>
